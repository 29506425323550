import React from "react"
import { Row, Col, Media } from "reactstrap"

const OwnerAvatar = ({ name, image }) => (
  <div className="text-center">
    <h4 className="mb-4">Co-Founder</h4>
    <Row className="mb-3 justify-content-center align-items-center">
      <Col md="4">
        <Media>
          <Media left top>
            <Media
              object
              src={image}
              alt={`${name}`}
              className="rounded-circle mr-3"
              style={{ width: "100px", height: "100px" }}
            />
          </Media>
          <Media body>
            <Media heading style={{ fontSize: "18px", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center" }}>{name}</Media>
          </Media>
        </Media>
      </Col>
    </Row>
  </div>
)

export default OwnerAvatar
